import Swiper from 'swiper/js/swiper.min';
import 'jquery-mousewheel';
import 'malihu-custom-scrollbar-plugin';
import Isotope from 'isotope-layout';
import 'magnific-popup';

const $win = $(window);
const $doc = $(document);
const $body = $('body');

// Detect Device ( includes Safari13 )
let isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream
if (!isMobile) {
	$body.addClass('no-touch');
} else {
	$body.addClass('is-touch');
};

$win.on('load', () => {
	$body.addClass('loaded');
});

$win.on('scroll', () => {
	$body.toggleClass('scrolled', $win.scrollTop() > 0);
});

$('.nav .ico-close, .nav-links .ico-close').on('click', function (e) {
	e.preventDefault();

	const $this = $(this);
	let $parent = $this.parents('.nav li');

	if ($('.nav-links').length) {
		$parent = $this.parents('.nav-links li')
	}

	if ($parent.hasClass('hover')) {
		$parent.removeClass('hover');
	}
});

// Nav Hover
if ($body.hasClass('no-touch')) {
	$('.nav > ul > li.mega-menu > a, .nav-links > ul > li.mega-menu > a').on('click', function (e) {
		const $this = $(this);
		const $parent = $this.parent();
		let $hasSubMenu = $this.next();

		if (!$parent.hasClass('hover')) {
			e.preventDefault();

			$parent.addClass('hover').siblings().removeClass('hover');
		} else {
			$parent.removeClass('hover');
		}
	});
}

if ($body.hasClass('is-touch')) {
	$('.nav > ul > li > a, .nav-links > ul > li > a').on('click', function (e) {
		const $this = $(this);
		let $parent = $this.parent();
		let $hasSubMenu = $this.next();

		if (!$parent.hasClass('hover')) {
			e.preventDefault();

			$parent.addClass('hover').siblings().removeClass('hover');

			$('.nav').addClass('active');
			$('.nav-links').addClass('active');
		}
	});
}

//  Filters trigger (mobile)
$('.listing-filters-wrapper').on('click', function (e) {
	e.preventDefault();

	$(this).toggleClass('open');
	$(".filters-nav").toggleClass('is-open');
	$body.toggleClass('show-nav');
});

// Close filters (mobile)
$('.close-nav').on('click', function (e) {
	$(this).removeClass('open');
	$(".filters-nav").removeClass('is-open');
	$body.removeClass('show-nav');
});

//  Nav trigger
$('.nav-trigger-wrapper, .nav-trigger-main').on('click', function (e) {
	e.preventDefault();

	$(this).toggleClass('open');
	$("#header-banner").toggle();
	$body.toggleClass('show-nav');
	$('.nav-wrapper').removeClass('active');

	if ($('.nav-inner > ul > li').hasClass('hover')) {
		$('.nav-inner > ul > li').removeClass('hover');
	}
});

$('.nav-trigger-events').on('click', function (e) {
	e.preventDefault();

	$(this).toggleClass('open');
	$body.toggleClass('show-nav-events');
});

if ($('.nav-inner').length) {
	let li = '<li><a href="#" class="link-back"></a><a href="#" class="ico-close"></a></li>';

	if ($win.width() <= 1023) {
		li = '<li><a href="#" class="link-back"></a></li>';
	}

	$('.nav-inner ul ul').prepend(li);

	$('.nav-inner > ul > li.menu-item-has-children > a').on('click', function (e) {
		const $this = $(this);
		let $parent = $this.parent();
		let $hasSubMenu = $this.next();
		let link = $this.text();

		if (!$parent.hasClass('hover')) {
			e.preventDefault();

			$parent.addClass('hover').siblings().removeClass('hover');
			$this.parents('.nav-wrapper').addClass('active');
		}

		$('.link-back').text(link);
	});

	// Nav Inner on Events pages
	$('.nav-wrapper > .ico-close').on('click', function (e) {
		e.preventDefault();

		$body.removeClass('show-nav');
		$('.nav-inner > ul > li').removeClass('hover');
		$('.nav-wrapper').removeClass('active');
	});

	$('.nav-inner ul ul .ico-close, .nav-inner .link-back').on('click', function (e) {
		e.preventDefault();

		$('.nav-inner > ul > li').removeClass('hover');
		$('.nav-wrapper').removeClass('active');
	});
}

$body.on('click touchstart', function (event) {
	let $target = $(event.target);

	if (!$target.parents('.nav li').length) {
		$('.nav li').removeClass('hover');
		$('.nav').removeClass('active');
	}

	if (!$target.parents('.nav-links li').length) {
		$('.nav-links li').removeClass('hover');
		$('.nav-links').removeClass('active');
	}

	if (!$target.parents('.form-search-small').length) {
		$('.form-search-small').removeClass('active');
	}

	if (!$target.parents('.nav-socials-sticky').length) {
		$('.nav-socials-sticky li').removeClass('active');
		$('.nav-socials-sticky li').removeClass('hidden');
	}

	if ($body.hasClass('is-touch') && $win.width() <= 768) {
		if (!$target.parents('.nav li').length) {
			$('.nav li').find('ul').removeClass('hover');
		}
	}
});

// Nav Socials Sticky
$win.on('load resize', () => {
	if ($('.nav-socials-sticky').length) {
		if ($body.hasClass('is-touch')) {
			$('.nav-socials-sticky li').on('click', function () {
				$(this)
					.addClass('active')
					.removeClass('hidden')
					.siblings()
					.removeClass('active')
					.addClass('hidden');
			});
		}
	}
});

// Header Search
$('.form-search-small .form__btn').on('click', function (e) {
	const $this = $(this);
	const $parent = $this.parents('.form-search-small');

	if (!$parent.hasClass('active')) {
		e.preventDefault();
		$parent.addClass('active');
	}
});

// Swiper slider

if ($win.width() <= 767) {
	$('.slider-articles .swiper-container').each((i, slider) => {
		const sliderArticles = new Swiper(slider, {
			spaceBetween: 35,
			loop: true,
			autoplay: {
				delay: 3500,
				disableOnInteraction: false,
			},
			speed: 1000,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
	});
}

$('.slider-logos-small .swiper-container').each((i, slider) => {
	const sliderLogosSmall = new Swiper(slider, {
		slidesPerView: 1,
		spaceBetween: 28,
		loop: true,
		autoplay: {
			delay: 3500,
			disableOnInteraction: false,
		},
		speed: 1000,
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			560: {
				slidesPerView: 2,
				spaceBetween: 28,
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 40,
			},
			1023: {
				slidesPerView: 4,
				spaceBetween: 40,
			},
			1200: {
				slidesPerView: 6,
				spaceBetween: 40,
			},
			1600: {
				slidesPerView: 6,
				spaceBetween: 60,
			},
			1921: {
				slidesPerView: 6,
				spaceBetween: 80,
			}
		}
	});
});

$('.slider-logos .swiper-container').each((i, slider) => {
	const sliderLogos = new Swiper(slider, {
		slidesPerView: 1,
		spaceBetween: 28,
		autoplay: {
			delay: 3500,
			disableOnInteraction: false,
		},
		speed: 1000,
		loop: true,
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		observer: true,
		observeParents: true,
		breakpoints: {
			560: {
				slidesPerView: 2,
				spaceBetween: 28,
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 40,
			},
			1023: {
				slidesPerView: 4,
				spaceBetween: 50,
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 60,
			},
			1921: {
				slidesPerView: 4,
				spaceBetween: 80,
			}
		}
	});
});

$('.slider-wines .swiper-container').each((i, slider) => {
	const sliderWines = new Swiper(slider, {
		slidesPerView: 1,
		spaceBetween: 28,
		loop: true,
		autoplay: {
			delay: 3500,
			disableOnInteraction: false,
		},
		speed: 1000,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		},
		observer: true,
		observeParents: true,
		breakpoints: {
			560: {
				slidesPerView: 2,
				spaceBetween: 28,
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 40,
			},
			1023: {
				slidesPerView: 4,
				spaceBetween: 50,
			},
			1200: {
				slidesPerView: 6,
				spaceBetween: 60,
			},
			1921: {
				slidesPerView: 6,
				spaceBetween: 80,
			}
		}
	});
});

$('.slider-images .swiper-container').each((i, slider) => {
	const sliderImages = new Swiper(slider, {
		slidesPerView: 2,
		spaceBetween: 10,
		loop: true,
		autoplay: {
			delay: 3500,
			disableOnInteraction: false,
		},
		speed: 1000,
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			375: {
				slidesPerView: 3,
				spaceBetween: 10,
			},
			560: {
				slidesPerView: 3,
				spaceBetween: 25,
			}
		}
	});

	$win.on('load resize', () => {
		let imageHeight = $('.slider-images .slide__image').outerHeight();
		let sliderPadding = parseInt($('.slider-images').css('padding-top'));
		let arrowsPosition = imageHeight / 2 + sliderPadding;

		$('.slider-images .swiper-button-prev, .slider-images .swiper-button-next').css('top', arrowsPosition);
	})
});

$('.slider-medias .swiper-container').each((i, slider) => {
	const sliderImages = new Swiper(slider, {
		slidesPerView: 2,
		spaceBetween: 40,
		loop: true,
		autoplay: {
			delay: 3500,
			disableOnInteraction: false,
		},
		speed: 1000,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			560: {
				spaceBetween: 80,
			},
			767: {
				slidesPerView: 3,
			},
			1100: {
				slidesPerView: 3,
				spaceBetween: 120,
			}
		}
	});
});

$('.slider-gallery .swiper-container').each((i, slider) => {
	if ($('.slider-gallery .slide').length > 1) {
		const sliderGallery = new Swiper(slider, {
			autoplay: {
				delay: 3500,
				disableOnInteraction: false,
			},
			speed: 1000,
			slidesPerView: 1,
			spaceBetween: 0,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				dynamicBullets: true,
			},
			breakpoints: {
				560: {
					slidesPerView: 2,
					spaceBetween: 20,
				},
				1000: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
			}
		});
	} else {
		$('.slider-gallery').addClass('has-one-slide');
	}
});

$('.slider-intro .swiper-container').each((i, slider) => {
	const sliderIntro = new Swiper(slider, {
		autoplay: {
			delay: 3500,
			disableOnInteraction: false,
		},
		speed: 1000,
		loop: true,
		spaceBetween: 40,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		},
	});
});

var galleryThumbs = new Swiper('.gallery-thumbs', {
	spaceBetween: 10,
	slidesPerView: 4,
	freeMode: true,
	watchSlidesProgress: true,
});

$('.slider-product .swiper-container').each((i, slider) => {
	const sliderProduct = new Swiper(slider, {
		loop: true,
		spaceBetween: 0,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		thumbs: {
			swiper: galleryThumbs
		}
	});
});

$('.slider-stats .swiper-container').each((i, slider) => {
	const sliderStats = new Swiper(slider, {
		slidesPerView: 1,
		autoplay: {
			delay: 3500,
			disableOnInteraction: false,
		},
		speed: 1000,
		loop: true,
		spaceBetween: 28,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			600: {
				slidesPerView: 2,
				spaceBetween: 40,
			},
			1023: {
				slidesPerView: 3,
				spaceBetween: 40,
			},
			1600: {
				slidesPerView: 3,
				spaceBetween: 60,
			},
			1921: {
				slidesPerView: 3,
				spaceBetween: 80,
			}
		}
	});
});

$('.slider-articles-small .swiper-container').each((i, slider) => {
	const sliderArticlesSmall = new Swiper(slider, {
		slidesPerView: 1,
		autoplay: {
			delay: 3500,
			disableOnInteraction: false,
		},
		speed: 1000,
		loop: true,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
			},
			1200: {
				slidesPerView: 3,
			},
			1600: {
				slidesPerView: 4,
			},
		}
	});
});

$('.slider-testimonials .swiper-container').each((i, slider) => {
	const sliderTestimonials = new Swiper(slider, {
		slidesPerView: 1,
		spaceBetween: 35,
		autoplay: {
			delay: 3500,
			disableOnInteraction: false,
		},
		speed: 1000,
		loop: true,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
				spaceBetween: 40,
			},
			1023: {
				slidesPerView: 3,
				spaceBetween: 40,
			},
			1600: {
				slidesPerView: 3,
				spaceBetween: 60,
			},
			1921: {
				slidesPerView: 3,
				spaceBetween: 80,
			},
		}
	});
});

// Malihu Custom Scroll
if ($('.slider-articles').length && $win.width() >= 768) {
	$('.slider-articles').mCustomScrollbar({
		callbacks: {
			onTotalScroll: (element) => {
				let $element = $('.slider-articles');

				$element.addClass('scrolled-to-bottom');
			},
			onScroll: (element) => {
				let $element = $('.slider-articles');

				if ($element.hasClass('scrolled-to-bottom')) {
					$element.removeClass('scrolled-to-bottom');
				}
			}
		}
	});
}

$('.tags').mCustomScrollbar({
	axis: "x"
});

// Isotope
$win.on('load', () => {
	if ($('.grid').length) {
		// Isotope
		const grid = document.querySelector('.grid');
		const istp = new Isotope(grid, {});

		// Images Loaded
		$('.grid').imagesLoaded();
	}
});

// Tabs

const activeTabClass = 'active';

$('.tabs .tabs__nav a').on('click', function (event) {
	event.preventDefault();

	let $tabLink = $(this);
	const $targetTab = $($tabLink.attr('href'));

	$tabLink = $tabLink.parent();

	$tabLink
		.add($targetTab)
		.addClass(activeTabClass)
		.siblings()
		.removeClass(activeTabClass);
});

// Magnific Popup
$('.js-gallery').each(function () {
	$(this).magnificPopup({
		type: 'image',
		delegate: 'a',
		gallery: {
			enabled: true
		}
	});
});

$('.js-video-popup').each(function () {
	$(this).magnificPopup({
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false
	});
});

// Video play button
$('.video .video__btn').on('click', function () {
	const $this = $(this);
	const $parent = $this.parent();
	const video = document.getElementById("video");
	const $iframe = $parent.find('iframe');

	$parent.addClass('play');

	if ($(video).length) {
		video.play();
	}

	if ($iframe.length) {
		const player = new YT.Player($iframe[0], {
			events: {
				onReady,
			}
		});

		function onReady(event) {
			event.target.playVideo();
		}
	}
});

// Button Scroll
$('a.btn-scroll').on('click', function (e) {
	e.preventDefault();

	const $this = $(this);
	let $section = $('.vc_ie-flexbox-fixer').length ? $this.parents('.vc_ie-flexbox-fixer') : $this.parents('.vc_row');
	const $next = $section.next();
	const offset = $next.offset().top - 63;

	$('html, body').animate({
		scrollTop: offset
	}, 600);
});

// Articles News overlay
if ($body.hasClass('is-touch')) {
	$('.article-news a').on('click', function (e) {
		e.preventDefault();

		const $this = $(this);
		const $parent = $this.parents('.article-news');

		$parent.addClass('active').siblings().removeClass('active');
	});

	$('.article-media a').on('click', function (e) {
		const $this = $(this);
		const $parent = $this.parents('li');

		if (!$parent.hasClass('active')) {
			e.preventDefault();

			$parent
				.addClass('active')
				.siblings()
				.removeClass('active');
		}
	});
}

$win.on('load scroll', function () {
	animateItems($win.scrollTop());

	$('.vc_row, .vc_row .vc_row').each(function () {
		if (isInViewport($(this))) {
			$(this).addClass('in-viewport');
		}
	});
});

// Detect if an element is in the viewport
function isInViewport(el, offset) {
	if (!el.length) {
		return false;
	}

	offset = offset || 0;

	var elementTop = el.offset().top + 100;
	var elementBottom = elementTop + el.height();
	var screenTop = $win.scrollTop() + offset;
	var screenBottom = screenTop + $win.height() + offset;

	if ((screenBottom > elementTop) && (screenTop < elementBottom)) {
		return true;
	} else {
		return false;
	}
}

// Animations for Blog and Post
function animateItems(topPosition) {
	const winHeight = $win.height();
	topPosition = topPosition;

	$('.article-single > *, .article-single .article__entry > *, .articles-news .article-news, .articles-media .article-media').each((index, el) => {
		const elementTop = $(el).offset().top - winHeight;
		if (topPosition < elementTop) {
			return;
		}

		setTimeout(() => {
			$(el).addClass('fade-in-up');
		}, 100 + index * 100);
	});
}

/**
 * Deep Linking
 *
 * @param  {String} url
 * @param  {String} hash
 * @return {Boolean}
 */

const winHash = window.location.hash;
const winHref = window.location.href;

$('.js-scroll-to a, .nav-wrapper a').each(function () {
	const $anchor = $(this);
	const $anchorHref = $anchor.attr('href');
	const $anchorHash = this.hash;
	const cleanedUrl = cleanURL($anchorHref, $anchorHash);

	$anchor.on('click', function (event) {
		if (!isSamePage(cleanedUrl)) {
			return;
		};

		scrollById($anchorHash);

		if ($anchorHash.length > 0) {
			if ($body.hasClass('show-nav')) {
				$body.removeClass('show-nav');
			}
		}
	});
});

$win.load(function () {
	if (winHash.length > 0) {
		$('body, html').scrollTop(0, 0);

		setTimeout(function () {
			scrollById(winHash);
		}, 500);
	}
});

/**
 * Scroll by given data-id.
 *
 * @param  {String} Id
 * @return {Void}
 */
function scrollById(id) {
	if (!id) {
		return false;
	};

	const $selector = $(`[id="${id}"]`);
	const $headerHeight = $('.header').outerHeight();

	if ($selector.length > 0) {
		$('body, html').animate({
			scrollTop: $selector.offset().top - $headerHeight
		}, 1000);
	};
}

/**
 * Check if the target is in the same page.
 *
 * @param  {String}  href
 * @return {Boolean}
 */
function isSamePage(url) {
	if (!url) {
		return;
	}

	const cleanedUrl = cleanURL(winHref, winHash);

	if (url === cleanedUrl) {
		return true;
	}

	return false;
}

/**
 * Clear page url.
 *
 * @param  {String} url
 * @param  {String} hash
 * @return {Boolean}
 */
function cleanURL(url, hash) {
	if (!url) {
		return false;
	};

	if (hash) {
		url = url.replace(hash, '');
	};

	return url;
}

// Google Map
function initializeGoogleMaps() {
	$('.map .map__inner').each((i, element) => {
		const $element = $(element);
		const lat = $element.data('lat');
		const lng = $element.data('lng');
		const center = new google.maps.LatLng($element.data('lat'), $element.data('lng'));

		if (!lat && !lng) {
			return;
		}

		const styles = [{ "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#e9e9e9" }, { "lightness": 17 }] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 20 }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }, { "lightness": 17 }] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "color": "#ffffff" }, { "lightness": 29 }, { "weight": 0.2 }] }, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 18 }] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 16 }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 21 }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#dedede" }, { "lightness": 21 }] }, { "elementType": "labels.text.stroke", "stylers": [{ "visibility": "on" }, { "color": "#ffffff" }, { "lightness": 16 }] }, { "elementType": "labels.text.fill", "stylers": [{ "saturation": 36 }, { "color": "#333333" }, { "lightness": 40 }] }, { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "geometry", "stylers": [{ "color": "#f2f2f2" }, { "lightness": 19 }] }, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [{ "color": "#fefefe" }, { "lightness": 20 }] }, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [{ "color": "#fefefe" }, { "lightness": 17 }, { "weight": 1.2 }] }]

		const mapOptions = {
			zoom: 10,
			center,
			backgroundColor: '#ffffff',
			panControl: false,
			scrollwheel: false,
			styles,
		};

		let icon = new google.maps.MarkerImage($element.data('marker'), null, null, null, new google.maps.Size(40, 58));

		const bounds = new google.maps.LatLngBounds();

		const map = new google.maps.Map(document.getElementById($element.attr('id')), mapOptions);

		const position = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));

		const marker = new google.maps.Marker({
			map,
			position,
			icon,
		});
	});
}

if ($('.map').length > 0) {
	google.maps.event.addDomListener(window, 'load', initializeGoogleMaps);
}

//Equalize height
$.fn.equalizeHeight = function () {
	var maxHeight = 0, itemHeight;

	for (var i = 0; i < this.length; i++) {
		itemHeight = $(this[i]).height();
		if (maxHeight < itemHeight) {
			maxHeight = itemHeight;
		}
	}

	return this.height(maxHeight);
}

$win.on('load', function () {
	$('.vc_row_table .table-head').equalizeHeight();
	$('.vc_row_table .table-body').equalizeHeight();
	$('.vc_row_table .table-foot').equalizeHeight();

	if ($win.width() > 1023) {
		$('.vc_row_table .table-head').removeAttr('style').equalizeHeight();
		$('.vc_row_table .table-body').removeAttr('style').equalizeHeight();
		$('.vc_row_table .table-foot').removeAttr('style').equalizeHeight();
	} else {
		$('.vc_row_table .table-head').removeAttr('style');
		$('.vc_row_table .table-body').removeAttr('style');
		$('.vc_row_table .table-foot').removeAttr('style');
	}
});

$win.on('resize', function () {
	if ($win.width() > 1023) {
		$('.vc_row_table .table-head').removeAttr('style').equalizeHeight();
		$('.vc_row_table .table-body').removeAttr('style').equalizeHeight();
		$('.vc_row_table .table-foot').removeAttr('style').equalizeHeight();
	} else {
		$('.vc_row_table .table-head').removeAttr('style');
		$('.vc_row_table .table-body').removeAttr('style');
		$('.vc_row_table .table-foot').removeAttr('style');
	}
});

$(".header-banner-title").on('click', () => $(".header-banner-content").toggle());
$(".header-banner-close").on('click', () => $(".header-banner-content").toggle());

//Articles popup
$('.open-popup-link').magnificPopup({
	type: 'inline',
	midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
});

//Gallery swiper popup
$('.slider-gallery-new').each(function () {
	$(this).magnificPopup({
		delegate: '.slider-gallery-popup',
		type: 'image',
		gallery: {
			enabled: true
		}
	});
});

document.addEventListener("DOMContentLoaded", () => {
	if (document.body.classList.contains("single-product")) {
		const salonPrices = Array.from(document.querySelectorAll(".single-salon-price"));
		const salonPricesNonExp = Array.from(document.querySelectorAll(".single-salon-price-non-exp"));
		const buyButtons = Array.from(document.querySelectorAll(".single-product-btn"));
		const contactButtons = Array.from(document.querySelectorAll(".single-product-contact-btn"));

		const toggleButtonState = (button, state) => {
			if (button) {
				button.classList.toggle("is-disabled", state);
			}
		};

		// Salon selector (exposant)
		document.querySelector(".salon-selector").addEventListener("change", (e) => {
			salonPrices.forEach(salonPrice => salonPrice.classList.remove("is-visible"));

			const goodPrice = salonPrices.find(salonPrice => salonPrice.dataset.index == e.target.value);

			if (goodPrice) {
				goodPrice.classList.add("is-visible");

				if (buyButtons) {
					buyButtons.map(button => button.classList.remove("is-active"));
					const buyBtn = buyButtons.find(button => button.dataset.id == goodPrice.dataset.id);

					if (buyBtn) {
						buyBtn.classList.add("is-active");
						toggleButtonState(buyBtn, false);
					}
				}
			} else {
				buyButtons.map(button => toggleButtonState(button, true));
			}
		});

		// Salon selector (non exposant)
		document.querySelector(".salon-selector-non-exp").addEventListener("change", (e) => {
			salonPricesNonExp.forEach(salonPrice => salonPrice.classList.remove("is-visible"));

			const goodPrice = salonPricesNonExp.find(salonPrice => salonPrice.dataset.index == e.target.value);

			if (goodPrice) {
				goodPrice.classList.add("is-visible");

				if (contactButtons) {
					contactButtons.map(button => button.classList.remove("is-active"));
					const contactBtn = contactButtons.find(button => button.dataset.id == goodPrice.dataset.id);

					if (contactBtn) {
						contactBtn.classList.add("is-active");
						toggleButtonState(contactBtn, false);
					}
				}
			} else {
				contactButtons.map(button => toggleButtonState(button, true));
			}
		});
	}
});

$(document).ready(function () {
	$(document).on('change', '.salon-selector', function () {
		const salonId = $(this).val();

		$('.single-salon-price').hide();
		$('.single-salon-price[data-id="' + salonId + '"]').show();

		$('.single-product-btn').hide();
		$('.single-product-btn[data-id="' + salonId + '"]').show().removeClass('is-disabled');
	});

	$(document).on('change', '.salon-selector-non-exp', function () {
		const salonId = $(this).val();

		$('.single-salon-price-non-exp').hide();
		$('.single-salon-price-non-exp[data-id="' + salonId + '"]').show();

		$('.single-product-contact-btn').hide();
		$('.single-product-contact-btn[data-id="' + salonId + '"]').show().removeClass('is-disabled');
	});
});